import React from 'react'
import './Technologies.css';
import digitalOcean from '../../photos/1200px-DigitalOcean_logo.svg.png';
import postman from '../../photos/postman-logo-F43375A2EB-seeklogo.com.png';
import pug from '../../photos/pug.png';
import nest from '../../photos/nestjs.png';
import next from '../../photos/next.png';
import postgresql from '../../photos/postgre.png';
import swagger from '../../photos/swagger.png';


function Technologies() {
  return (
    <div id='technologies'>
      <div className='subContent-Technologies'>
        <h4>Technologies</h4>
        <div className='technologies'>
          <div className='technologyImg'>
            <i className="fab fa-html5 technologyIcon"></i>
          </div>
          <div className='technologyImg'>
            <i className="fab fa-css3-alt technologyIcon"></i>
          </div>
          <div className='technologyImg'>
            <i className="fab fa-node technologyIcon"></i>
          </div>
          <div className='technologyImg'>
            <img className='img-technology' alt='Express JS' src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/64/Expressjs.png/330px-Expressjs.png' ></img>
          </div>
          <div className='technologyImg'>
            <i className="fab fa-react technologyIcon"></i>
          </div>
          <div className='technologyImg'>
            <img className='img-technology' alt='MongoDB' src='https://webassets.mongodb.com/_com_assets/cms/mongodb_atlas-h0ai1yctwo.svg' ></img>
          </div>
          <div className='technologyImg'>
            <img className='img-technology-right' src={nest} alt='NestJs'></img>
          </div>
          <div className='technologyImg'>
            <img className='img-technology-right' src={next} alt='NextJs'></img>
          </div>
          <div className='technologyImg'>
            <img className='img-technology' alt='Jquery' src='https://upload.wikimedia.org/wikipedia/en/thumb/9/9e/JQuery_logo.svg/330px-JQuery_logo.svg.png' ></img>
          </div>
          <div className='technologyImg'>
            <img alt='MySql' src='https://cdn4.iconfinder.com/data/icons/logos-3/181/MySQL-128.png'></img>
          </div>
          <div className='technologyImg'>
            <img className='img-technology-pug' alt='Pug JS' src={pug}></img>
          </div>
          <div className='technologyImg'>
            <img className='img-technology-pug' alt='Swagger' src={swagger}></img>

          </div>
          <div className='technologyImg'>
            <i className="fab fa-php technologyIcon"></i>
          </div>
          <div>
            <img className='img-technology-right'  alt='Mocha' src={postgresql}></img>
          </div>
          <div>
            <img className='img-technology-right' src={postman} alt='Postman'></img>
          </div>
          <div>
            <img className='img-technology' src={digitalOcean} alt='Digital Ocean'></img>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Technologies
